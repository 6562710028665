import React from 'react'
import Hero from '../components/Hero'
import Signup from '../components/Signup'

const Home = () => (
  <main>
    <Hero />
    <Signup />
  </main>
);

export default Home;
