import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/devices'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.options}, 1fr);
  border: 1px solid ${props => props.theme.border};
  border-radius: 5px;
  font-size: 13px;
  color: ${props => props.theme.secondary};

  @media ${device.laptop}{
    font-size: 16px;
  }
`;

const Option = styled.div`
  padding: 1rem;
  cursor: pointer;
  text-align: center;
  background: ${props => props.selected ? props.theme.border : `transparent` };
  border-right: 1px solid ${props => props.theme.border};

  &:hover {
    background: ${props => props.theme.border};
  }

  &:last-of-type {
    border: 0;
  }
`;

const Switch = ({ onChange, options, selected }) => (
  <Wrapper options={options.length}>
    {options.map(option => (
      <Option key={option.value} selected={selected === option.value} onClick={() => onChange(option.value)}>
        {option.name}
      </Option>
    ))}
  </Wrapper>
);

export default Switch;
