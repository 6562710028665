const theme = {
  // Type
  body: 'Times Now',
  headline: 'Canela',
  sans: 'Aktiv Grotesk',

  // Colors
  primary: 'rgb(255,238,147)',
  secondary: 'rgb(54,33,62)',
  highlight: 'rgb(255,192,159)',
  border: 'rgb(54,33,62,0.15)',
  bg: 'rgb(241, 239, 241)'
}

export default theme;
