import React from 'react'
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Box = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.sans};
  font-size: 14px;

  span {
    margin-left: 1rem;
  }
`;

const Checkbox = ({ label, value, checked, onChange }) => (
  <Box data-testid='checkbox' onClick={() => onChange(value, !checked)}>
    {checked ? <MdCheckBox data-testid='checkbox-checked-icon' size={18} /> : <MdCheckBoxOutlineBlank data-testid='checkbox-unchecked-icon' size={18} /> }
    <span data-testid='checkbox-label'>{label}</span>
  </Box>
);

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Checkbox;
