import React, { useState } from 'react'
import styled from 'styled-components'
import useForm from 'react-hook-form'
import Input from './Input'
import Button from './Button'
import Checkbox from './Checkbox'
import { Carousel } from 'react-responsive-carousel'
import image from '../assets/buyer1.jpg'
import image2 from '../assets/buyer2.jpg'
import { device } from '../utils/devices'
import encode from '../utils/encode'

const Wrapper = styled.div`
.slider {
  position: relative;
  grid-column: 3 / span 2;
  grid-row: 2 / span 3;
  display: block;
  z-index: 1;

  picture {
    height: 100%;
    background: #FDF9E5;
    display: block;
  }

  .carousel {
    overflow: visible;
    overflow-y: visible;
    margin-bottom: 4rem;

    .slide {
      background: rgba(54, 33, 62, 0.15);
      box-sizing: border-box;
      padding: 1rem;
    }

    .control-dots {
      bottom: -2rem;
      padding: 0;

      .dot {
        outline: 0;
        background: black;
        box-shadow: none !important;
      }
    }
  }

  .slider-wrapper {
    overflow-x: hidden;
    overflow-y: visible;
  }

  .carousel, .slider-wrapper, .slider {
    height: 100%;
  }

  @media ${device.laptop}{
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: contain;
    /* mix-blend-mode: multiply; */

    &.fill {
      object-fit: cover;
    }
  }
}
`;

const Form = styled.form`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
`;

const CustomerForm = ({ notify }) => {

  const { register, handleSubmit, errors, reset } = useForm()

  const [options, setOptions] = useState({
    produce: false,
    prepared: false,
    beverages: false,
    household: false,
    beauty: false
  })

  const handleCheckbox = (option, value) => {
    console.log('option', value)
    setOptions({...options, [option]: value})
  }

  const items = [
    { label: 'Produce', value: 'produce', checked: options.produce },
    { label: 'Prepared foods', value: 'prepared', checked: options.prepared },
    { label: 'Beverages', value: 'beverages', checked: options.beverages },
    { label: 'Household items', value: 'household', checked: options.household },
    { label: 'Beauty products', value: 'beauty', checked: options.beauty },
  ]

  const onSubmit = data => {
    const itemsFiltered = items.filter(el => el.checked !== false )
    const intentions = itemsFiltered.map(item => item.checked ? item.label : null)
    const intentionsString = intentions.toString()



    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "buyer", "intentions": intentionsString,...data })
    })
    .then(() =>  {
      reset();
      setOptions({
        produce: false,
        prepared: false,
        beverages: false,
        household: false,
        beauty: false
      });
      notify();
    })
    .catch(error => alert(error));
  }

  return (
    <Wrapper>
    <Carousel
      className='slider'
      showIndicators
      autoPlay
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      infiniteLoop
    >
      <img src={image} alt='product page' />
      <img src={image2} alt='product page' />
    </Carousel>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input name='firstName' type='text' placeholder='First Name' ref={register({ required: true })} errors={errors.firstName} />
      <Input name='lastName' type='text' placeholder='Last Name' ref={register({ required: true, pattern: /^[A-Za-z]+$/i })} errors={errors.lastName} />
      <Input name='email' type='email' placeholder='Email Address' ref={register({ required: true })} errors={errors.email} />
      <p>What products are you interested in buying?</p>
      {items.map(item => (
        <Checkbox key={item.label} checked={item.checked} value={item.value} label={item.label} onChange={handleCheckbox} />
      ))}
      <Input name='products' type='text' placeholder="Let us know more about what products you would like to buy" errors={errors.products} />
      <Button>Sign up for Updates</Button>
    </Form>
    </Wrapper>
  )
}

export default CustomerForm;
