import React, { useState } from "react";
import styled from "styled-components";
import { device } from "../utils/devices";
import Switch from "./Switch";
import CustomerForm from "./CustomerForm";
import SellerForm from "./SellerForm";
import PartnerForm from "./PartnerForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Wrapper = styled.section`
  background: white;
  padding: 1rem;
  box-sizing: border-box;
  margin: 2rem auto 2rem;
  width: 90vw;
  color: ${props => props.theme.secondary};

  @media ${device.laptop} {
    display: grid;
    padding: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  .form-container {
    padding: 1rem;
    border: 1px solid ${props => props.theme.border};
    border-radius: 5px;

    @media ${device.laptop} {
      padding: 4rem;
    }
  }

  h2 {
    font-weight: 100;
    margin: 0;
    font-family: ${props => props.theme.headline};
    margin-bottom: 2rem;
    font-size: 2rem;
    line-height: 2rem;

    @media ${device.laptop} {
      font-size: 3rem;
      padding-right: 4rem;
      line-height: 4rem;
    }

    @media ${device.laptopL} {
      font-size: 4rem;
      line-height: 5rem;
    }
  }

  h4 {
    font-family: ${props => props.theme.headline};
    font-weight: 20;
    font-size: 2rem;
    border-top: 1px solid ${props => props.theme.border};
    padding-top: 2rem;
  }

  h5 {
    font-size: 16px;
    font-weight: normal;
    text-align: center;

    @media ${device.laptop} {
      text-align: left;
    }
  }

  hr {
    margin: 2rem 0;
    border: 0;
    border-top: 1px solid ${props => props.theme.border};
  }

  p {
    font-family: ${props => props.theme.sans};
    font-size: 13px;
    line-height: 1.3rem;
    box-sizing: border-box;

    @media ${device.laptop} {
      padding-right: 2rem;
    }

    @media ${device.laptopL} {
      line-height: 2rem;
      font-size: 14px;
    }
  }

  @media ${device.laptop} {
    width: 95vw;
    padding: 4rem;
  }
`;

const options = [
  { name: "Customer", value: "customer" },
  { name: "Seller", value: "seller" },
  { name: "Partner", value: "partner" }
];

const Signup = () => {
  const [option, setOption] = useState("customer");

  const notify = () =>
    toast.success("Submitted! Thank you", {
      position: toast.POSITION.TOP_CENTER
    });

  const renderForm = option => {
    switch (option) {
      case "seller":
        return <SellerForm notify={notify} />;
      case "partner":
        return <PartnerForm notify={notify} />;
      default:
        return <CustomerForm notify={notify} />;
    }
  };

  return (
    <Wrapper>
      <div>
        <h2>Join Local Guru</h2>
        <h4>Our Goal</h4>
        <p>
          We're here to disrupt the food system. We want to provide our
          customers with great biodiverse local food options by sourcing produce
          and artisanal goods close to home. We do this by vetting hundreds of
          farmers and vendors, providing only the best and most trusted sources
          to you through our online market.
        </p>
        <h4>We Create, You Customize</h4>
        <p>
          As a subscriber, you are able to add or remove whatever items that you
          want to your basket - as long as your minimum order is $20. Your
          basket is delivered bi-weekly by default, but you can make it weekly
          or suspend it as needed with one click in your account settings. You
          can cancel at any time.
        </p>
      </div>
      <div className="form-container">
        <h5>I'm interested in becoming a</h5>
        <Switch options={options} onChange={setOption} selected={option} />
        <hr />
        {renderForm(option)}
      </div>
      <ToastContainer />
    </Wrapper>
  );
};

export default Signup;
