import React from "react";
import styled from "styled-components";
import small from "../assets/hero-600.jpg";
import medium from "../assets/hero-1200.jpg";
import large from "../assets/hero-1600.jpg";
import shopS from "../assets/shop-600.jpg";
import shopM from "../assets/shop-1200.jpg";
import shopL from "../assets/shop-1600.jpg";
import mobileS from "../assets/mobile-600.jpg";
import mobileM from "../assets/mobile-1200.jpg";
import mobileL from "../assets/mobile-1600.jpg";
import { device } from "../utils/devices";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Mission from "./Mission";

const Wrapper = styled.section`
  color: white;
  box-sizing: border-box;
  position: relative;

  @media ${device.laptop} {
    width: 95vw;
    margin: 4rem auto 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, min-content);
  }

  .slider {
    position: relative;
    grid-column: 3 / span 2;
    grid-row: 2 / span 3;
    display: block;
    z-index: 1;

    picture {
      height: 100%;
      background: #fdf9e5;
      display: block;
    }

    .carousel {
      overflow: visible;
      overflow-y: visible;

      .control-dots {
        bottom: auto;
        top: -4rem;
        width: 50%;
        padding: 0;

        .dot {
          outline: 0;
          background: black;
          box-shadow: none !important;
        }
      }
    }

    .slider-wrapper {
      overflow-x: hidden;
      overflow-y: visible;
    }

    .carousel,
    .slider-wrapper,
    .slider {
      height: 100%;
    }

    @media ${device.laptop} {
      z-index: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      /* mix-blend-mode: multiply; */

      &.fill {
        object-fit: cover;
      }
    }
  }
`;

const Text = styled.div`
  width: 90vw;
  position: relative;
  margin: -2rem auto 0;
  padding: 2rem;
  background: ${props => props.theme.primary};
  background: white;
  box-sizing: border-box;
  color: ${props => props.theme.secondary};
  z-index: 2;

  @media ${device.laptop} {
    width: auto;
    padding: 4rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column: 1 / span 3;
    grid-row: 1 / span 4;
    margin: 0;
    z-index: 1;
  }

  div {
    grid-column: span 2;
  }

  h2 {
    font-weight: 100;
    margin: 0;
    font-family: ${props => props.theme.headline};
    margin-bottom: 2rem;
    font-size: 2rem;
    line-height: 2rem;

    @media ${device.laptop} {
      font-size: 3rem;
      padding-right: 4rem;
      line-height: 4rem;
    }

    @media ${device.laptopL} {
      font-size: 4rem;
      line-height: 5rem;
    }
  }

  p {
    font-family: ${props => props.theme.sans};
    font-size: 13px;
    line-height: 1.3rem;
    box-sizing: border-box;

    @media ${device.laptop} {
      padding-right: 2rem;
    }

    @media ${device.laptopL} {
      line-height: 2rem;
      font-size: 14px;
    }
  }
`;

// const ButtonGroup = styled.div`
//   margin-top: 2rem;
//
//   @media ${device.tablet}{
//     display: flex;
//   }
//
//   button {
//     width: 100%;
//     margin: 0 0 1rem 0;
//
//     @media ${device.tablet}{
//       width: auto;
//       margin: 0 2rem 0 0;
//     }
//
//     &:last-of-type {
//       margin-right: 0;
//     }
//   }
// `;

const Image = () => (
  <picture>
    <img
      className="fill"
      src={small}
      alt="Hero"
      srcSet={`${small} 600w, ${medium} 1200w, ${large} 1600w`}
    />
  </picture>
);

const ShopImage = () => (
  <picture>
    <img
      src={shopS}
      alt="Hero"
      srcSet={`${shopS} 600w, ${shopM} 1200w, ${shopL} 1600w`}
    />
  </picture>
);

const MobileImage = () => (
  <picture>
    <img
      src={mobileS}
      alt="Hero"
      srcSet={`${mobileS} 600w, ${mobileM} 1200w, ${mobileL} 1600w`}
    />
  </picture>
);

const Hero = () => (
  <Wrapper>
    <Carousel
      className="slider"
      showIndicators
      autoPlay
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      infiniteLoop
    >
      <Image />
      <ShopImage />
      <MobileImage />
    </Carousel>
    <Text>
      <div>
        <h2>Eat Locally. Eat Fresh. Eat Responsibly.</h2>
        <p>
          Local Guru offers all the best in goods from local farms and
          producers. We partner directly with the source to offer you a curated,
          carefully sourced selection of produce and grab-and-go options. Our
          mission is to make locally grown food easily accessible for our
          community to enjoy.  We believe in the power of healthy eating. Food
          grown without harsh chemicals is best for our bodies and the
          environment. We source food grown close to home, cutting down travel
          time and limiting exposure to toxins for guaranteed fresher food.
        </p>
        <Mission />
      </div>
    </Text>
  </Wrapper>
);

export default Hero;
