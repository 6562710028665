import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Foot = styled.footer`
  padding: 2rem 2.5vw 4rem;

  a {
    color: ${props => props.theme.secondary};
    text-decoration: none;
  }

  ul {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-right: 2rem;

      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }
`;

const Footer = () => (
  <Foot>
    <ul>
      <li>&copy; Local Guru 2019</li>
      <li><a href="mailto:localguru.io@gmail.com">Contact us</a></li>
      <li><Link to='/faqs'>FAQs</Link></li>
      <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
    </ul>
  </Foot>
);

export default Footer;
