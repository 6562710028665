import React from 'react'
import Header from './components/Header'
import theme from './theme'
import { ThemeProvider } from 'styled-components'
import Footer from './components/Footer'
import { Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
import PrivacyPolicy from './pages/PrivacyPolicy'
import FAQs from './pages/FAQs'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div data-testid='app'>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
          <Route path='/faqs' component={FAQs} />
        </Switch>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
