import React, { useState } from "react";
import styled from "styled-components";
import Input from "./Input";
import Button from "./Button";
import Checkbox from "./Checkbox";
import useForm from "react-hook-form";
import encode from "../utils/encode";

const Form = styled.form`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;

  p {
    margin: 0;
  }
`;

const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Product = styled.div`
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 4px 4px 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  border-radius: 6px;

  span {
    cursor: pointer;
    margin-left: 6px;

    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

const SellerForm = ({ notify }) => {
  const { register, handleSubmit, errors, reset } = useForm();

  const [options, setOptions] = useState({
    produce: false,
    prepared: false,
    beverages: false,
    household: false,
    beauty: false
  });

  const [products, setProducts] = useState([]);
  const [productsField, setProductsField] = useState("");

  const items = [
    { label: "Produce", value: "produce", checked: options.produce },
    { label: "Prepared foods", value: "prepared", checked: options.prepared },
    { label: "Beverages", value: "beverages", checked: options.beverages },
    {
      label: "Household items",
      value: "household",
      checked: options.household
    },
    { label: "Beauty products", value: "beauty", checked: options.beauty }
  ];

  const handleCheckbox = (option, value) => {
    console.log("option", value);
    setOptions({ ...options, [option]: value });
  };

  const handleProductField = value => {
    const lastChar = value.charAt(value.length - 1);
    if (lastChar === ",") {
      const newTag = value.split(",")[0];
      console.log(newTag);
      setProductsField("");
      setProducts(prevValues => [...prevValues, newTag]);
    } else {
      setProductsField(value);
    }
  };

  const handleRemoveProduct = index => {
    const newArray = [...products];
    newArray.splice(index, 1);
    setProducts(newArray);
  };

  const handleEnter = () => {
    setProducts(prevValues => [...prevValues, productsField]);
  };

  const onSubmit = data => {
    const itemsFiltered = items.filter(el => el.checked !== false);
    const intentions = itemsFiltered.map(item =>
      item.checked ? item.label : null
    );
    const intentionsString = intentions.toString();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "seller",
        intentions: intentionsString,
        products: products,
        ...data
      })
    })
      .then(() => {
        reset();
        reset();
        setOptions({
          produce: false,
          prepared: false,
          beverages: false,
          household: false,
          beauty: false
        });
        setProducts([]);
        setProductsField("");
        notify();
      })
      .catch(error => alert(error));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="firstName"
        type="text"
        placeholder="First Name"
        ref={register({ required: true })}
        errors={errors.firstName}
      />
      <Input
        name="lastName"
        type="text"
        placeholder="Last Name"
        ref={register({ required: true })}
        errors={errors.lastName}
      />
      <Input
        name="email"
        type="email"
        placeholder="Email Address"
        ref={register({ required: true })}
        errors={errors.email}
      />
      <Input
        name="tel"
        type="tel"
        placeholder="Phone Number"
        ref={register({ required: true })}
        errors={errors.tel}
      />
      <Input
        name="source"
        type="tel"
        placeholder="How did you find out about us?"
        ref={register({ required: true })}
        errors={errors.source}
      />
      <p>What products are you interested in selling?</p>
      {items.map(item => (
        <Checkbox
          key={item.label}
          checked={item.checked}
          value={item.value}
          label={item.label}
          onChange={handleCheckbox}
        />
      ))}
      <Input
        onChange={e => handleProductField(e.target.value)}
        value={productsField}
        placeholder="Let us know more about what products you would like to sell"
        errors={errors.products}
        onKeyPress={e => {
          if (e.key === "Enter") {
            handleEnter();
          }
        }}
      />
      <p>Enter your products, separate with a comma</p>

      {products.length > 0 && (
        <ProductList>
          {products.map((product, index) => (
            <Product key={product}>
              {product}{" "}
              <span onClick={() => handleRemoveProduct(index)}>
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="close"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                </svg>
              </span>
            </Product>
          ))}
        </ProductList>
      )}
      <Button disabled={products.length === 0}>Sign up for Updates</Button>
    </Form>
  );
};

export default SellerForm;
