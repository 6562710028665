import React from 'react'
import styled from 'styled-components'
import Input from './Input'
import Button from './Button'
import useForm from 'react-hook-form'
import encode from '../utils/encode'

const Form = styled.form`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;

  p {
    margin: 0;
  }
`;

const PartnerForm = ({ notify }) => {

  const { register, handleSubmit, errors, reset } = useForm()

  const onSubmit = data => {

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "partner", ...data })
    })
    .then(() =>  {
      reset();
      notify()
    })
    .catch(error => alert(error));
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input name='firstName' type='text' placeholder='First Name' ref={register({ required: true })} errors={errors.firstName} />
      <Input name='lastName' type='text' placeholder='Last Name' ref={register({ required: true })} errors={errors.lastName} />
      <Input name='email' type='email' placeholder='Email Address' ref={register({ required: true })} errors={errors.email} />
      <Input name='tel' type='tel' placeholder='Phone Number' ref={register({ required: true })} errors={errors.tel} />
      <Input name='source' type='text' placeholder='How did you find out about us?' ref={register({ required: true })} errors={errors.source} />
      <Input name='synergy' type='text' placeholder='How could we build synergy together?' ref={register({ required: true })} errors={errors.synergy} />
      <Button>Sign up for Updates</Button>
    </Form>
  )
}

export default PartnerForm;
