import React from 'react'
import styled from 'styled-components'
import { MdError } from 'react-icons/md'

const Error = styled.div`
  position: absolute;
  right: 1rem;
  top: 0;
  height: 100%;
  color: #D8000C;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  display: flex;
  box-sizing: border-box;
  font-size: 14px;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const FormError = ({ children }) => (
  <Error><MdError size={18} /><span data-testid='form-error'>{children}</span></Error>
);

export default FormError;
