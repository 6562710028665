import React from 'react'
import styled from 'styled-components'
import logo from '../assets/logo.svg'
import { device } from '../utils/devices'
import { Link } from 'react-router-dom'

const Head = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 2rem 4rem;
  background: white;
  box-sizing: border-box;
  box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.05);
  z-index: 10;
  text-align: center;

  @media ${device.laptop}{
    text-align: left;
  }
`;

const Header = () => (
  <Head>
    <Link to='/'><img src={logo} alt='Local Guru' /></Link>
  </Head>
);

export default Header
