import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/devices'

const Wrapper = styled.div`
  width: 90vw;
  margin: 4rem auto;
  max-width: 1100px;
  color: ${props => props.theme.secondary};

  h2 {
    margin-bottom: 2rem;
    font-size: 24px;
  }

  a {
    color: ${props => props.theme.secondary};
  }

  section {
    background: white;
    padding: 2rem;
    box-sizing: border-box;
    margin-bottom: 2rem;

    h4 {
      font-size: 16px;

      @media ${device.laptop}{
        font-size: 18px;
      }
    }

    p {
      line-height: 1.4em;
    }
  }
`;

const FAQs = () => (
  <Wrapper>
    <h2>FAQs</h2>
    <section>
      <h4>Where and When do you deliver?</h4>
      <p>We deliver to the twin cities and surrounding suburbs. All deliveries are on Saturdays between 12pm and 5pm.</p>
    </section>
    <section>
      <h4>What are my delivery options? Is there a long term commitment?</h4>
      <p>You can subscribe to weekly, bi-weekly, or single order. Upon subscription, you will be automatically enrolled in a bi-weekly order using your original order. You can adjust the details of your order at any time. You can also skip deliveries and stop the subscription with us whenever you want.</p>
    </section>
    <section>
      <h4>Do I have to be home to receive my delivery?</h4>
      <p>No. You can mark your food to be dropped off at your front door. We recommend picking up the food as soon as possible and putting it away in a proper storage place.</p>
    </section>
    <section>
      <h4>What if I miss my delivery?</h4>
      <p>First, email us at <a href="mailto:localguru.io@gmail.com">localguru.io@gmail.com</a> to see if your delivery is still out and can be dropped off.  If your order has already been returned, you can pick it up in South Minneapolis. Your order should be ready for pick up after 2:30pm to 4:45pm on Saturday.</p>
    </section>
    <section>
      <h4>What if I want to pick up my order?</h4>
      <p>If you prefer to pick up your order, please check the box for pick up. You can pick up your order up at our community partner location in South Minneapolis. Your order should be ready for pick up from after  12pm to 4:45pm on Saturday.  If you do not pick up your food by 5pm Saturday, you can pick up the food Sunday between 10am and 2pm. If you do not pick up the food you forfeit the order, as our partner location does not have a place to house the food.</p>
    </section>
    <section>
      <h4>Is there a delivery fee?</h4>
      <p>All orders under $40 have a $9.99 delivery fee. Any order over $40 is eligible for free delivery.</p>
    </section>
    <section>
      <h4>What is your cancellation policy?</h4>
      <p>You can change, postpone or cancel your service at any time. The only restriction is you must log into your account and make these changes before Thursday at 10am prior to your Saturday delivery. If you need to cancel after the Thursday at 10am deadline email us at <a href="mailto:localguru.io@gmail.com">localguru.io@gmail.com</a> and we will do our best to accommodate you.</p>
    </section>
    <h2>Billing</h2>
    <section>
      <h4>When do you charge me?</h4>
      <p>The Thursday prior to your Saturday delivery.</p>
    </section>
    <section>
      <h4>What credit cards do you accept?</h4>
      <p>Visa, and MasterCard credit/debit cards.</p>
    </section>
    <section>
      <h4>What is your refund policy?</h4>
      <p>In the event that you are not satisfied with your order we will refund you 100 percent of the charges. If you are unsatisfied with part of your order we will either replace, refund or credit your account depending on your request.</p>
    </section>
    <section>
      <h4>What happens if my credit card declines?</h4>
      <p>If your credit card is declined, we will inform you to update your account information. We will pause the delivery until the payment is satisfied.</p>
    </section>
    <section>
      <h4>What happens if my credit card declines?</h4>
      <p>If your credit card is declined, we will inform you to update your account information. We will pause the delivery until the payment is satisfied.</p>
    </section>
    <h2>Produce</h2>
    <section>
      <h4>What if an item is of poor quality?</h4>
      <p>Let us know right away. We expect top quality food just like you do, if an unacceptable items gets to you, we will replace it with your next order.</p>
    </section>
    <section>
      <h4>Where does the produce come from?</h4>
      <p>You get to pick which farmers you prefer to deal with and every item you purchase, the site will show you who the food comes from and their story.</p>
    </section>
    <section>
      <h4>How fresh is your produce?</h4>
      <p>Orders are collected every Saturday morning, and we deliver them directly to your door directly from the grower.</p>
    </section>
    <section>
      <h4>Why is our produce better than what you can buy in the grocery store?</h4>
      <p>The produce we deliver is picked and delivered to you, unlike grocery store produce that is picked ahead of time to withstand the shipping and shelf life of the store. With time, store produce loses its nutrition and taste unlike ours, which goes straight to you after delivery from producer</p>
    </section>
  </Wrapper>
);

export default FAQs;
