import React, { forwardRef } from "react";
import styled from "styled-components";
import FormError from "./FormError";
import { device } from "../utils/devices";

const Wrapper = styled.div`
  position: relative;
`;

const Field = styled.input`
  padding: 1rem;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  border: ${props =>
    props.error ? `1px solid #D8000C` : `1px solid ${props.theme.border}`};
  font-size: 16px;
  background: transparent;
  width: 100%;
  box-sizing: border-box;

  @media ${device.laptop} {
    font-size: 14px;
  }
`;

const Input = forwardRef((props, ref) => {
  const { errors, onChange } = props;
  return (
    <Wrapper>
      <Field
        data-testid="input"
        ref={ref}
        onChange={e => onChange && onChange(e)}
        error={errors ? true : false}
        {...props}
      />
      {errors && errors.type === "required" && (
        <FormError>This is required</FormError>
      )}
    </Wrapper>
  );
});

export default Input;
