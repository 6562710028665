import React from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import styled from "styled-components";
import { device } from "../utils/devices";

const List = styled.ul`
  padding: 3rem 0 0;
  margin: 2rem 0 0 0;
  border-top: 1px solid ${props => props.theme.border};
  list-style: none;
  font-size: 13px;
  font-family: ${props => props.theme.sans};

  @media ${device.laptop} {
    font-size: 14px;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    svg {
      flex-shrink: 0;
      margin-right: 1rem;
    }
  }

  h4 {
    font-family: ${props => props.theme.headline};
    font-size: 2rem;
    font-weight: 200;
    margin: 0 0 2rem;
  }
`;

const Mission = () => (
  <List>
    <h4>Our Mission</h4>
    <li>
      <IoIosCheckmarkCircleOutline size={30} /> To foster a network of thriving
      Local Farms, Urban Farms, and Community Gardens
    </li>
    <li>
      <IoIosCheckmarkCircleOutline size={30} />
      To support sustainable growing practices
    </li>
    <li>
      <IoIosCheckmarkCircleOutline size={30} /> To support sustainable growing
      practices
    </li>
    <li>
      <IoIosCheckmarkCircleOutline size={30} /> To build new local economies and
      strengthen food ecosystems
    </li>
  </List>
);

export default Mission;
