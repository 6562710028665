import React from "react";
import styled from "styled-components";
import { device } from "../utils/devices";

const StyledButton = styled.button`
  border: 1px solid ${props => props.theme.secondary};
  border: 0;
  background: ${props => props.theme.primary};
  padding: 0.75rem 1.5rem;
  font-family: ${props => props.theme.sans};
  font-size: 1rem;
  font-size: 14px;
  outline: none;
  font-weight: 600;
  border-radius: 4px;
  appearance: none;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  width: 100%;
  cursor: ${props => (props.disabled ? `not-allowed` : `pointer`)};

  @media ${device.laptopL} {
    padding: 1.5rem 2rem;
    font-size: 1rem;
  }

  &:hover {
    transform: translate(0, -3px);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  }
`;

const Button = ({ children, primary, disabled }) => (
  <StyledButton disabled={disabled} data-testid="button">
    {children}
  </StyledButton>
);

export default Button;
